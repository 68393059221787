// ==========[ FONTS ]==========

$ff-opensans: 'Open Sans', sans-serif;
$caps: uppercase;

// ==========[ COLORS ]==========

$white         : #FFFFFF;
$grey-light    : #F6F6F6;
$grey          : #939598;
$grey-dark     : #414042;
$black         : #000000;
$red		   : #CE3F3F;

// ==========[ MIXINS ]==========

@mixin boxshad() {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@mixin softshadow() {
  box-shadow: 0px 10px 30px -5px rgba(0,0,0,.3);
}

@mixin quart-transition($duration) {
  transition: all $duration cubic-bezier(0.770, 0.000, 0.175, 1.000);
}
