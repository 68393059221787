// Extra small devices (portrait phones, less than 576px)

@media
  (max-width : 575.98px) {
  .off-canvas-nav {
    right : -100vw;
    width : 100vw;
  }
}

// Small devices (landscape phones, less than 768px)

@media
  (max-width : 767.98px) {
}

// Medium devices (tablets, less than 992px)

@media
  (max-width : 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right : 15px;
    padding-left  : 15px;
  }
}

// Large devices (desktops, less than 1200px)

@media
  (max-width : 1199.98px) {
}
