// ==========[ GENERAL ]==========

* {
	font-feature-settings  : "kern"1;
	font-kerning           : normal;
	-webkit-font-smoothing : antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family            : $ff-opensans;
	text-transform         : $caps;
}

.container {
	padding: 0 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin : 0;
	padding: 0;
}

h1 {
	font-size  : 2.5vw;
	font-weight: bold;
	padding    : 20px;
}

h2 {
	padding-top: 50px;
	font-weight: 800;
	text-align : center;
	display    : block !important;
}

h3 {
	color      : $black;
	font-size  : 24px;
	font-weight: 700;
	line-height: 36px;
}

h4 {
	color      : $black;
	font-size  : 18px;
	font-weight: bolder;
}

h5 {
	font-size     : 14px;
	color         : $black;
	font-weight   : bold;
	padding-bottom: 10px;
	padding-top   : 20px;
}

a {
	font-weight: bold;
	color      : $red;

	&:hover {
		color: $grey-dark;
	}
}

.nav-itemDropdown {
	padding-top: 20px;
}

.activeDropdown {
	border-bottom: 3px solid $red;
}

.grey-bg {
	background-color: $grey-light;
}

.cms {

	p,
	ul {
		color         : $grey;
		font-size     : 18px;
		line-height   : 29px;
		display       : block;
		text-transform: none;

		b {
			font-weight: bold;
		}

		a {
			color          : $red;
			text-decoration: underline;

			&:hover {
				color          : $grey-dark;
				text-decoration: none;
			}
		}
	}
}

.navbar {
	padding: 2rem 1rem;
}

.cta {
	padding: 40px;
	border : 2px $grey-light solid;
}

header {
	font-size  : 14px;
	font-weight: bold;

	a {
		color: $black;

		&:hover {
			color: $red;
		}
	}

	#logo {
		width    : 30vw;
		max-width: 250px;
		float    : left;
	}


	.offCanvasToggler:hover {
		cursor: pointer;
		color : $red;

		p,
		button {
			color: $red;
		}
	}


	li {
		margin-left: 0px;
		padding    : 0;

		&.active {

			border-bottom: 3px solid $red;
		}
	}

	.btn {
		padding: 8px;
	}

	.top-bar {
		width           : 100%;
		background-color: $grey-light;
		height          : 40px;

		.top-info {
			display: inherit;

			p {
				line-height: 40px;

				a {
					text-transform: none;
					color         : $grey;
				}
			}
		}

		ul {
			display: inherit;

			li {
				list-style-type: none;
				padding-top    : 0;
				line-height    : 40px;

				.activeDropdown {
					color : $red;
					border: none;
				}

				a {
					color: $black;

					&:hover {
						color: $red;
					}
				}
			}
		}
	}

}

.btn-primary:hover {
	color: $black;
}

.btn:hover {
	text-decoration: none;
}

.diensten {
	margin : 0;
	padding: 0;

	a {
		margin-bottom: 40px;
		display      : block;

		p {
			padding    : 45px;
			color      : $white;
			font-size  : 18px;
			font-weight: bold;
			text-align : center;
		}
	}

}

.brand {
	img {
		transition: all, 0.5s ease-in-out;
	}

	&:hover {
		img {
			margin-left: 25%;
			transform  : translate(50%);
			transform  : scale(1.1);
		}
	}
}


#overlay {
	background-color: rgba(0, 0, 0, .5);
}


.zolder {
	padding      : 210px 9vw;
	text-align   : center;
	margin-bottom: 50px;

	h1 {
		color: $white;
	}

	div {
		@extend .buttonOfBel;
	}
}


.buttonOfBel {
	div {
		font-size: 14px;
	}

	p {
		display     : inline-block;
		font-size   : 14px;
		padding-left: 15px;
		font-weight : bold;
		color       : $black;
	}


}


.row {
	padding-bottom: 20px;
}

p.gray {
	text-transform: none;
	color         : #939598;
	font-size     : 16px;
	padding       : 0 20px;
}

.center {
	margin-left: 50%;
	transform  : translate(-50%, 0);
}

.buttonAlign {
	@extend .buttonOfBel;
	margin-left: -15px;
	padding    : 20px 0;
}

.btn:hover {
	background-color: $red;
}


@media screen and (min-width: 768px) {
	p.gray {
		padding: 0;
	}
}


@media screen and (max-width: 1200px) {
	h1 {
		font-weight: bold;
		padding    : 20px;
		color      : $black;
	}

	.zolder {
		padding: 100px 9vw;
	}


}



.news {
	vertical-align: bottom;
	margin-left   : -16px;

	span {
		vertical-align: bottom;
	}

	.text {
		padding   : 30px;
		border    : 1px solid #E5E5E5;
		border-top: none;

		p {
			text-transform: none;
			padding       : 20px 0;
		}
	}

	.newsItem {
		display         : block;
		margin-top      : 20px;
		position        : relative;
		width           : 100%;
		background-color: $red;

		&:hover {
			color: $red;
		}
	}

	.image {
		opacity            : 1;
		width              : 100%;
		display            : block;
		height             : auto;
		transition         : .5s ease;
		backface-visibility: hidden;
	}

	.vergrootglas {
		transition: .5s ease;
		opacity   : 0;
		position  : absolute;
		top       : 50%;
		left      : 50%;
		transform : translate(-50%, -50%);
	}

	.newsItem:hover .image {
		opacity: 0.3;
	}

	.newsItem:hover .vergrootglas {
		opacity: 1;
	}
}

#br {
	color           : $white;
	background-color: $red;
	padding         : 50px 0;

	h2 {
		padding    : 10px;
		font-size  : 28px;
		font-weight: 800;
	}

	p {
		font-weight : bold;
		font-size   : 14px;
		padding-left: 20px;
	}

	#vrijOfferte {
		padding-top    : 20px;
		margin         : 0 auto;
		display        : flex;
		justify-content: center;

		p {
			display    : inline-block;
			line-height: 50px;
		}

	}
}


footer {
	background-color: $grey-light;

	.logo {
		margin-top: 10px;
		width     : 13vw;
		max-width : 175px;
	}

	.nav p,
	.nav a {
		text-transform: uppercase;
	}

	p,
	a {
		color  : #939598;
		display: block;
	}

	a:hover {
		color: $red;
	}

	p {
		text-transform: none;
	}

	img {
		padding-right: 3px;
	}
}

.realisatie-item {
	display        : block;
	text-decoration: none;

	.image {
		display            : block;
		position           : relative;
		width              : 100%;
		padding-top        : 75%;
		background-repeat  : no-repeat;
		background-position: center;
		background-size    : cover;

		.overlay {
			display         : flex;
			position        : absolute;
			top             : 0;
			right           : 0;
			bottom          : 0;
			left            : 0;
			color           : $white;
			opacity         : 0;
			background-color: transparentize($red, .2);
			align-items     : center;
			font-size       : 14px;
			justify-content : center;
			transition      : all 400ms cubic-bezier(0.645, 0.045, 0.355, 1.000);

			&:hover {
				opacity  : 1;
				font-size: 26px;
			}
		}
	}

	&:hover {
		text-decoration: none;

		h4 {
			color     : $red;
			transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
		}
	}
}


@media screen and (min-width: 1070px) {
	header {
		li {
			margin-left: 2vw;
			padding    : 0;

			&.active {

				border-bottom: 3px solid $red;
			}
		}
	}
}

.merken {
	div>div {
		height        : 70px;
		padding-bottom: 10px;
		text-align    : center;

		img {
			width     : 150%;
			height    : auto;
			margin-top: 50%;
			transform : translate(0%, -50%);
		}
	}

	.center {
		display     : block;
		margin-right: auto;
	}
}

.btn-image {
	display            : block;
	position           : relative;
	width              : 100%;
	padding-top        : 75%;
	background-repeat  : no-repeat;
	background-position: center;
	background-size    : cover;
}

.btn-image .overlay {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	position          : absolute;
	top               : 0;
	right             : 0;
	bottom            : 0;
	left              : 0;
	color             : #fff;
	background-color  : rgba(0, 0, 0, .2);
	text-align        : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-transition: all .4s cubic-bezier(.77, 0, .175, 1);
}

.noPhoto .overlay {
	background-color: rgba(255, 255, 255, 1);

	p {
		transition: all .4s cubic-bezier(.77, 0, .175, 1);
		color     : $black;
	}
}

.btn-image:hover .overlay {
	background: rgba(200, 54, 54, 0.6);

	p {
		color     : $white;
		transition: all .4s cubic-bezier(.77, 0, .175, 1);
	}
}

.btn {
	background-color: $red;
	color           : $white;
	font-weight     : bold;
	padding         : 15px;
	vertical-align  : middle;
	border          : none;
	border-radius   : 0px;

	a {
		color: $white;
	}
}

.btn-active {
	color           : $black;
	background-color: transparent;
	border          : 2px $red solid;

	&:hover {
		color           : $black;
		background-color: $grey-light;
		border          : 2px $grey-light solid;
	}
}

.btn-primary:active {
	background-color: $red !important;
	//padding       : 8px;
	//margin        : 3px 7.25px;
	font-size       : 13px;
	color           : $black;
}

.btn-primary:hover {
	background-color: $red !important;
	// padding      : 8px;
	// margin       : 3px 7.25px;
	font-size       : 13px;
	$color          : $black;
}

div.btn-inverted {
	font-weight     : bold;
	font-size       : 14px;
	background-color: $white !important;
	border-color    : $white;

	a {
		color: $red;
	}
}

div.btn-inverted:hover {
	background-color: $white !important;
	text-decoration : underline;
	color           : $red !important;
}

.btn-inverted:active {
	background-color: $red !important;
	padding         : 8px;
	margin          : 3px 7.25px;
	font-size       : 13px;
}

.meer {
	float    : right;
	color    : #939598;
	font-size: 14px;
}

.service-content {
	h2 {
		text-align : start;
		padding-top: 0;
	}
}

hr {
	margin: 0;
	border: 1px solid $grey-light;
}

// EXTRA LARGE
@media screen and (min-width: 1200px) {
	header {
		li {
			margin-left: 2vw;
			padding    : 0;

			&.active {

				border-bottom: 3px solid $red;
			}
		}

		h1 {
			font-size: 40px;
		}
	}
}

// LARGE
@media screen and (max-width: 1199px) {}

// MEDIUM
@media screen and (max-width: 991px) {
	footer {
		.logo {
			width     : 20vw;
			margin-top: 20px;
		}
	}

	h1 {
		font-size: 25px;
	}

	h2 {
		font-size: 20px;
	}

	.zolder {
		padding: 80px 7vw;
	}

	.br>h2 {
		font-size: 18px;
	}
}

@media screen and (min-width: 744px) {
	.top-bar {
		ul {
			li {
				margin-left: 20px;
			}
		}
	}

	.nav-itemDropdown {
		margin-left: 25px;
	}
}


// SMALL
@media screen and (max-width: 767px) {
	.merken {
		div>div {
			height        : 70px;
			padding-bottom: 10px;

			img {
				height    : 100%;
				width     : 100%;
				margin-top: 0;
				transform : translate(0, 0);
			}
		}
	}

	header {
		li {
			margin-left: 1vw;
		}
	}

	.faded {
		display: none;
	}

	#IsoLN {
		padding-left: 20px;
	}

	h1 {
		font-size: 20px;
	}

	h2 {
		text-align: center;
		display   : block;
	}

	.zolder {
		padding: 12px 1vw;
	}

	.buttonAlign {
		margin-left: 5px;
	}


	#br {
		h2 {
			font-size: 18px;
		}

		#vrijOfferte {
			margin: auto;
		}

	}

	footer p {
		font-size: 15px;
	}

}

.offer {
	.cms {
		text-align: center;
	}
}

// EXTRA SMALL
@media screen and (max-width: 575px) {
	footer {
		text-align: center;

		#sitemapL {
			text-align: left;
		}

		#sitemapR {
			text-align: right;
			max-width : none;
		}

		.logo {
			width: 30vw;
		}
	}

	header {
		li {
			margin-left: 1vw;
		}
	}

	.zolder {
		h1 {
			padding  : 10px;
			font-size: 18px;
		}

		.btn-primary {
			padding: 10px;
		}
	}

	.meer {
		display   : inline-block;
		width     : 100%;
		text-align: center;
	}

}

#video {
	min-height     : 300px;
	position       : relative;
	top            : 50%;
	left           : 50%;
	transform      : translate(-50%, -50%);
	background     : no-repeat;
	background-size: cover;

	.overlay {
		display        : flex;
		position       : absolute;
		top            : 0;
		right          : 0;
		bottom         : 0;
		left           : 0;
		color          : $white;
		opacity        : 1;
		align-items    : center;
		font-size      : 14px;
		justify-content: center;
		transition     : all 400ms cubic-bezier(0.645, 0.045, 0.355, 1.000);

		&:hover {
			background-color: transparentize($red, .2);
			font-size       : 26px;

			.play-button {
				transform: translate(-50%, -50%);
			}
		}
	}

	.play-button {
		border          : 30px solid $red;
		border-radius   : 50%;
		background-color: $red;
		color           : $white;
		position        : absolute;
		top             : 50%;
		left            : 50%;
		transform       : translate(-50%, -50%);

		svg {
			height   : 20px;
			width    : 20px;
			position : absolute;
			top      : 50%;
			left     : 50%;
			transform: translate(-50%, -50%);
		}
	}
}