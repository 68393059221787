// ==========[ OFF CANVAS MENU ]==========

.no-scrolling {
  height   : 100%;
  overflow : hidden;
}

.navbar-toggler {
  display         : flex;
  color           : $black;
  align-items     : center;
  justify-content : flex-start;

  p {
    color : $black;
  }
}

.off-canvas-nav {
  z-index         : 9999;
  z-index         : 2;
  display         : flex;
  position        : fixed;
  top             : 0;
  right           : -520px;
  bottom          : 0;
  width           : 520px;
  padding         : 40px;
  border-left     : 2px solid $grey-light;
  background      : $white;
  align-items     : flex-start;
  flex-direction  : column;
  justify-content : space-between;
  transition      : right 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  section {
    display         : flex;
    width           : 100%;
    align-items     : flex-start;
    flex-direction  : column;
    justify-content : flex-start;

    .burger-menu {
      align-self : flex-end;
    }
  }

  ul {
    margin  : 0;
    padding : 0;

    li {
      margin     : 20px 0;
      list-style : none;

      a {
        color       : $black;
        font-size   : 16px;
        font-weight : 700;

        &.active {
          color       : red;
          font-weight : 700;
        }

        &:hover {
          text-decoration : none;
        }
      }
    }
  }

  &.active {
    display    : flex;
    right      : 0;
    box-shadow : -10px 0px 80px 0px rgba(0,0,0,0.25);
  }
}

.fullscreen-overlay {
  z-index          : 1;
  position         : absolute;
  top              : 0;
  left             : 0;
  width            : 0;
  height           : 0;
  background-color : transparentize(#000000, 1);
  transition       : background-color 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  &.active {
    top              : 0;
    right            : 0;
    bottom           : 0;
    left             : 0;
    width            : auto;
    height           : auto;
    background-color : transparentize(#000000, .5);
  }
}
