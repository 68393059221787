// ==========[ OFF CANVAS MENU ]==========

.no-scrolling {
    height   : 100%;
    overflow : hidden;
  }
  
  .navbar-toggler {
    display         : flex;
    color           : $white;
    align-items     : center;
    justify-content : flex-start;
  
    p {
      color : $white;
    }
  }
  
  .off-canvas-nav {
    z-index         : 9999;
    z-index         : 2;
    display         : flex;
    position        : fixed;
    top             : 0;
    right           : -520px;
    bottom          : 0;
    width           : 520px;
    padding         : 40px;
    border-left     : 2px solid $grey-light;
    background      : $white;
    align-items     : flex-start;
    flex-direction  : column;
    justify-content : space-between;
    transition      : right 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  
    section {
      display         : flex;
      width           : 100%;
      align-items     : flex-start;
      flex-direction  : column;
      justify-content : flex-start;
  
      .burger-menu {
        align-self : flex-end;
      }
    }
  
    ul {
      margin  : 0;
      padding : 0;
  
      li {
        margin     : 20px 0;
        list-style : none;
  
        a {
          color          : $black;
          font-size      : 16px;
          font-weight    : 700;
          letter-spacing : 1.5px;
  
          &.active {
            color       : $red;
            font-weight : 700;
          }
  
          &:hover {
            text-decoration : none;
          }
        }
      }
    }
  
    &.active {
      display    : flex;
      right      : 0;
      box-shadow : -10px 0px 80px 0px rgba(0,0,0,0.25);
    }
  }
  
  .fullscreen-overlay {
    z-index          : 1;
    position         : absolute;
    top              : 0;
    left             : 0;
    width            : 0;
    height           : 0;
    background-color : transparentize(#000000, 1);
    transition       : background-color 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  
    &.active {
      top              : 0;
      right            : 0;
      bottom           : 0;
      left             : 0;
      width            : auto;
      height           : auto;
      background-color : transparentize(#000000, .5);
    }
  }
  
  // ==========[ BUTTONS ]==========
  
  .btn {
    padding        : 13px 18px;
    border-radius  : 0;
    text-transform : uppercase;
    font-family    : $ff-opensans;
    font-size      : 13px;
    font-weight    : bold;
    letter-spacing : 1.5px;
  }
  
  .btn-big {
    padding   : 16px 28px;
    font-size : 16px;
  }
  
  .btn-primary {
    color            : $white;
    border           : 2px $red solid;
    background-color : $red;
  
    &:hover {
      color            : $white;
      //border           : 2px darken($red, 7%) solid;
      background-color : darken($red, 7%);
    }
  }
  
  .btn-secondary {
    color            : $white;
    border           : 2px $white solid;
    background-color : transparent;
  
    &:hover {
      color            : $white;
      //border           : 2px darken($red, 7%) solid;
      background-color : darken($red, 7%);
    }
  }
  
  .btn-image {
    display             : block;
    position            : relative;
    width               : 100%;
    padding-top         : 75%;
    background-repeat   : no-repeat;
    background-position : center;
    background-size     : cover;
  
    .overlay {
      display          : flex;
      position         : absolute;
      top              : 0;
      right            : 0;
      bottom           : 0;
      left             : 0;
      color            : $white;
      background-color : transparentize($black, .8);
      text-align       : center;
      align-items      : center;
      justify-content  : center;
      transition       : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  
      h4 {
        color : $white;
      }
  
      &:hover {
        background-color : transparentize($red, .2);
      }
    }
  }
  
  .btn-huge {
    display     : block;
    position    : relative;
    width       : 100%;
    padding-top : 75%;
  
    .overlay {
      display         : flex;
      position        : absolute;
      top             : 0;
      right           : 0;
      bottom          : 0;
      left            : 0;
      color           : $grey-dark;
      border          : 2px $grey-light solid;
      text-align      : center;
      align-items     : center;
      justify-content : center;
      transition      : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  
      h4 {
        color      : $grey-dark;
        transition : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }
  
      &:hover {
        border           : 2px $red solid;
        background-color : $red;
  
        h4 {
          color : $white;
        }
      }
    }
  }
  
  .btn-back {
    color          : $grey;
    text-transform : uppercase;
    font-size      : 13px;
    font-weight    : bold;
    letter-spacing : 1.5px;
  
    &:hover {
      color           : $black;
      text-decoration : none;
    }
  }
  
  .btn-link {
    color          : $red;
    text-transform : uppercase;
    font-size      : 13px;
    font-weight    : bold;
    letter-spacing : 1.5px;
  
    &:hover {
      color           : $black;
      text-decoration : none;
    }
  }
  
  .btn-filter {
    color            : $black;
    border           : 2px $grey-light solid;
    background-color : transparent;
  
    &:hover {
      color            : $black;
      background-color : $grey-light;
    }
  
    &.active {
      color            : $white;
      background-color : $red;
    }
  }
  
  // ==========[ HELPER CLASSES ]==========
  
  .title-centered {
    position : absolute;
    top      : 36px;
  }
  
  .sharing {
    color           : $grey;
    text-decoration : none;
    font-size       : 18px;
  
    &:hover {
      color           : $red;
      text-decoration : none;
    }
  }
  
  // ==========[ ELEMENTS ]==========
  
  .video-block {
    display             : block;
    position            : relative;
    width               : 100%;
    padding-top         : 100%;
    background-position : center;
    background-size     : cover;
  
    .overlay {
      display          : flex;
      position         : absolute;
      top              : 0;
      right            : 0;
      bottom           : 0;
      left             : 0;
      background-color : transparentize($grey-dark, 1);
      align-items      : center;
      justify-content  : center;
      transition       : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  
      .button {
        display          : flex;
        width            : 67px;
        height           : 67px;
        color            : $white;
        border-radius    : 999px;
        background-color : $red;
        align-items      : center;
        justify-content  : center;
        transition       : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }
  
      &:hover {
        background-color : transparentize($red, .2);
  
        .button {
          color      : $red;
          background : $white;
          transform  : scale(1.15);
        }
      }
    }
  }
  
  .fullscreen-gallery {
    display         : flex;
    width           : 100vw;
    align-items     : center;
    flex-wrap       : wrap;
    justify-content : flex-start;
  
    .image {
      z-index             : 0;
      display             : block;
      position            : relative;
      width               : 16.666vw;
      height              : 16.666vw;
      background-position : center;
      background-size     : cover;
      transition          : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  
      .overlay {
        display          : flex;
        position         : absolute;
        top              : 0;
        right            : 0;
        bottom           : 0;
        left             : 0;
        color            : $white;
        opacity          : 0;
        background-color : transparentize($red, .2);
        align-items      : center;
        font-size        : 16px;
        justify-content  : center;
        transition       : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }
  
      &:hover {
        z-index   : 10;
        transform : scale(1.15);
  
        .overlay {
          opacity   : 1;
          font-size : 26px;
        }
      }
    }
  }
  
  .small-gallery {
    display         : flex;
    width           : 100%;
    align-items     : flex-start;
    flex-wrap       : wrap;
    justify-content : flex-start;
  
    .image {
      display             : block;
      position            : relative;
      width               : 25%;
      padding-top         : 25%;
      background-position : center;
      background-size     : cover;
      transition          : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  
      .overlay {
        display          : flex;
        position         : absolute;
        top              : 0;
        right            : 0;
        bottom           : 0;
        left             : 0;
        color            : $white;
        opacity          : 0;
        background-color : transparentize($red, .2);
        align-items      : center;
        font-size        : 16px;
        justify-content  : center;
        transition       : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }
  
      &:hover {
        z-index   : 10;
        transform : scale(1.15);
  
        .overlay {
          opacity   : 1;
          font-size : 26px;
        }
      }
    }
  }
  
  .nieuws-item {
    display         : block;
    text-decoration : none;
    cursor          : pointer;
  
    .image {
      width               : 100%;
      padding-top         : 75%;
      background-position : center;
      background-size     : 100%;
      transition          : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
    }
  
    .content {
      padding       : 40px;
      border-right  : 2px $grey-light solid;
      border-bottom : 2px $grey-light solid;
      border-left   : 2px $grey-light solid;
      transition    : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  
      h4 {
        transition : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }
    }
  
    &:hover {
      text-decoration : none;
      color: $red;
      
      .content {
        box-shadow : 0 0 50px rgba(0, 0, 0, .1);
      }
       
      .image {
        background-size : 115%;
      }
    }
  }
  
  .image-with-button {
    display             : block;
    position            : relative;
    width               : 100%;
    padding-top         : 75%;
    background-position : center;
    background-size     : cover;
  
    .overlay {
      display          : flex;
      position         : absolute;
      top              : 0;
      right            : 0;
      bottom           : 0;
      left             : 0;
      background-color : transparentize($grey-dark, 1);
      align-items      : center;
      justify-content  : center;
      transition       : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  
      .button {
        display          : flex;
        width            : 67px;
        height           : 67px;
        color            : $white;
        border-radius    : 999px;
        background-color : $red;
        align-items      : center;
        justify-content  : center;
        transition       : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }
  
      &:hover {
        background-color : transparentize($red, .2);
  
        .button {
          color      : $red;
          background : $white;
          transform  : scale(1.15);
        }
      }
    }
  }
  
  .map {
    width       : 100%;
    padding-top : 100%;
  }

  // ==========[ EDIT BUTTON ]==========
.edit__page__button {
  @include           quart-transition(400ms);
  display          : flex;
  position         : fixed;
  right            : 20px;
  bottom           : 20px;
  width            : 50px;
  height           : 50px;
  color            : $white;
  border-radius    : 999px;
  background-color : $red;
  align-items      : center;
  font-size        : 18px;
  justify-content  : center;

  &:hover {
    @include           softshadow;
    color            : $red;
    background-color : $white;
  }
}  


////// FORM CONTACT
.frm-contact {
	label {
		text-transform: uppercase;
		font-weight: bold;

		span {
			color: $red;
		}
	}

	.form-control {
		border: 1px solid black;
	}
}

#feedback-content {
	background: $grey-light;
	padding: 15px;
	color: $red;
}
